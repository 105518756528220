import { render, staticRenderFns } from "./captcha.vue?vue&type=template&id=13ea6412&scoped=true&"
import script from "./captcha.vue?vue&type=script&lang=js&"
export * from "./captcha.vue?vue&type=script&lang=js&"
import style0 from "./captcha.vue?vue&type=style&index=0&id=13ea6412&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ea6412",
  null
  
)

export default component.exports