<template>
	<div class="container">
		<div class="row cc title">请输入短信验证码</div>
		<div class="row cc mobile">
			<span>{{ mobile }}</span>
			<van-icon name="edit" @click="editMobile()" />
		</div>
		<div class="row cb input-view">
			<div class="item" v-for="(item, index) in captchaArr" :key="index">{{ item }}</div>
			<div class="item" v-for="(v, k) in 6 - captchaArr.length" :key="'n' + k"></div>
			<input type="number" autofocus="autofocus" v-model="captcha" oninput="if(value.length>6)value=value.slice(0,6)" />
		</div>
		<div class="row cc timer" v-if="time == 60">
			<span>验证码已发送，</span>
			<span class="green" @click="again()">点击重新获取</span>
		</div>
		<div class="row cc timer" v-else>
			<span>验证码已发送，{{ time }}秒后重新获取</span>
		</div>
	</div>
</template>

<script>
import Cookie from 'js-cookie';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			mobile: '',
			captcha: '',
			time: 59,
			intval: ''
		};
	},
	created() {
		this.mobile = this.$route.query.mobile;
		if (process.env.NODE_ENV == 'production') {
			this.send();
		}
	},
	computed: {
		...mapState(['userInfo']),
		captchaArr() {
			return this.captcha.split('').splice(0, 6);
		}
	},
	watch: {
		async captcha(val) {
			if (val.length == 6) {
				if (this.$route.query.bind) {
					let res = await this.$service.post('user_info/bindMobile', {
						mobile: this.mobile,
						smscode: this.captcha
					});
					this.$toast({
						type: 'success',
						message: '绑定成功'
					});
					this.$app.$emit('bindSuccess');
					setTimeout(() => {
						this.$router.go(-1);
					}, 800);
				} else {
					console.log(this.userInfo);
					let openid = this.userInfo.openid;
					let nickname = this.userInfo.nickname;
					let avatar = this.userInfo.avatar;

					if (process.env.NODE_ENV === 'development') {
						openid = 'oLV0e5sTn01ZpDFdSo5RGiTygvbw';
						nickname = 'Y_Lin';
						avatar = 'https://thirdwx.qlogo.cn/mmopen/vi_32/tUJa4yicv8cKaibGzHHWU25FaJLcuWzlwO0uWWpR2eq0pqC7YK2naINw7RecptS6ibvO4J64uFGIaaibedOITUUpZA/132';
					}
					let res = await this.$service.post('auth/login', {
						mobile: this.mobile,
						smscode: this.captcha,
						openid,
						nickname,
						avatar
					});
					this.$store.dispatch('setToken', res.data.token);
					this.$store.dispatch('setUserId', res.data.user_id);
					this.$toast({
						type: 'success',
						message: '登录成功'
					});
					let path = this.$store.getters.lastPath;
					setTimeout(() => {
						this.$store.dispatch('setLastPath', '');
						if (path) {
							this.$router.replace({
								path
							});
						} else {
							this.$router.back();
						}
					}, 800);
				}
			}
		}
	},
	methods: {
		editMobile() {
			this.$router.replace({
				path: this.$route.query.bind ? '/bind' : '/login'
			});
		},
		async send() {
			await this.$service.post('user_info/getsmsCode', { mobile: this.mobile });
			this.$toast({
				type: 'success',
				message: '验证码已发送'
			});
			this.intval = setInterval(() => {
				if (this.time == 0) {
					this.time = 60;
					clearInterval(this.intval);
				} else {
					this.time--;
				}
			}, 1000);
		}
	}
};
</script>

<style scoped lang="less">
@import './captcha.less';
</style>
